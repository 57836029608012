import React from "react";
import TallyForm from "../components/TallyForm";
import { Helmet } from "react-helmet-async";

const Begin = () => {
	return (
		<div className="pt-8">
			<Helmet>
				<title>Love on Trial | Begin</title>
				<meta
					name="description"
					content="At Love on Trial, we believe in being thorough throughout our process. While we've kept things private until now, we're excited to open up our matching process to more potential partners. This onboarding form is designed to help us determine if you're the right fit for Love on Trial and to understand how you could potentially match with others on the show."
				/>
			</Helmet>
			<TallyForm
				url={"https://tally.so/r/wajkxb?transparentBackground=1"}
				title="Let's get to know YOU 🫵🏽"
			/>
		</div>
	);
};

export default Begin;
