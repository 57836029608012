import React from "react";

interface TallyFormProps {
	url: string;
	title?: string;
	height?: string;
}

const TallyForm: React.FC<TallyFormProps> = ({
	url,
	title = "Tally Form",
	height = "100%",
}) => {
  return (
		<iframe
			title={title}
			src={url}
			width="100%"
			height={height}
			style={{ border: "none", margin: 0 }}
			allow="fullscreen"
		></iframe>
	);
};

export default TallyForm;
