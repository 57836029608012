import React from "react";
import Layout from "../components/Layout";
import { Link, useNavigate } from "react-router-dom";
import { PATHS } from "../routes/routes.path";
import { Helmet } from "react-helmet-async";

const Home = () => {
	const navigate = useNavigate();
	return (
		<Layout>
			<Helmet>
				<title>Love on Trial | Welcome</title>
			</Helmet>
			<div className="h-[100vh]">
				<div className="flex flex-col justify-center items-center m-auto h-full">
					<img
						src="/assets/love-on-trial-logo.png"
						alt="love on trial"
						className="w-48"
					/>
					<h1 className="font-cinzel text-4xl text-center">Love on trial</h1>
					<h3 className="font-amaranth text-secondary text-center">
						The journey to your final verdict starts now...
					</h3>
					<button
						className="font-cinzel-decorative mt-12 border border-primary bg-tertiary px-5 py-1 rounded-full"
						onClick={() => navigate(PATHS.BEGIN)}
					>
						Begin
					</button>
					<div className="absolute bottom-24 lg:bottom-4 flex flex-col items-center">
						<div className="font-cinzel text-base flex items-center space-x-6">
							<Link to={"/faqs"}>FAQS</Link>
							<h3>~</h3>
							<Link to={"/about"}>ABOUT</Link>
						</div>
					</div>
				<p className="text-[8px] mt-6 text-gray-400 absolute bottom-0">
					Copyright © 2024 The Love On Trial Show. All rights reserved.
				</p>
				</div>
			</div>
		</Layout>
	);
};

export default Home;
