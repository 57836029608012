import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Routes } from "./routes";
import { useLocation } from "react-router-dom";
import Loader from "./components/Loader";
// import CustomCursor from "./components/CustomCursor";
import TrailCursor from "./components/TrailCursor";

function App() {
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setIsLoading(true);
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 2000); // Simulate navigation delay
		return () => clearTimeout(timer);
	}, [location]);

	return (
		<HelmetProvider>
			{isLoading ? <Loader /> : <Routes />}
			{/* <CustomCursor /> */}
			<TrailCursor />
		</HelmetProvider>
	);
}

export default App;
