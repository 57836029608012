import React from "react";
import { useNavigate } from "react-router-dom";

interface ILayoutProps {
	children: React.ReactNode;
}

const Layout = ({ children }: ILayoutProps) => {
	const navigate = useNavigate();
	return (
		<div className="relative h-screen bg-white`">
			<div>{children}</div>
			<img
				src="/assets/love-on-trial-logo.png"
				alt="love on trial"
				className="w-12 opacity-30 absolute top-0 left-0 cursor-pointer"
				onClick={() => navigate("/")}
			/>
			<img
				src="/assets/love-on-trial-logo.png"
				alt="love on trial"
				className="w-12 opacity-30 absolute top-0 right-0 cursor-pointer"
				onClick={() => navigate("/")}
			/>
			<img
				src="/assets/love-on-trial-logo.png"
				alt="love on trial"
				className="w-12 opacity-30 absolute bottom-0 left-0 cursor-pointer"
				onClick={() => navigate("/")}
			/>
			<img
				src="/assets/love-on-trial-logo.png"
				alt="love on trial"
				className="w-12 opacity-30 absolute bottom-0 right-0 cursor-pointer"
				onClick={() => navigate("/")}
			/>
		</div>
	);
};

export default Layout;
