import { useRoutes } from "react-router-dom";
import Home from "../pages/Home";
import { PATHS } from "./routes.path";
import Begin from "../pages/Begin";
import FAQ from "../pages/FAQ";
import About from "../pages/About";

export function Routes() {
	let element = useRoutes([
		{
			path: PATHS.HOME,
			index: true,
			element: <Home />,
		},
		{
			path: PATHS.BEGIN,
			index: true,
			element: <Begin />,
		},
		{
			path: PATHS.FAQ,
			index: true,
			element: <FAQ />,
		},
		{
			path: PATHS.ABOUT,
			index: true,
			element: <About />,
		},
	]);
	return element;
}
