import React from "react";
import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../routes/routes.path";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Helmet } from "react-helmet-async";

const FAQ = () => {
	const navigate = useNavigate();

	return (
		<Layout>
			<Helmet>
				<title>Love on Trial | FAQs</title>
			</Helmet>
			<div className="h-screen flex flex-col items-center justify-center py-16 px-4">
				<div className="flex justify-between items-center lg:w-[90%] w-full mb-6">
					<IoIosArrowRoundBack
						className="text-4xl text-deep-primary cursor-pointer"
						onClick={() => navigate("/")}
					/>
					<h1 className="text-4xl font-bold font-cinzel text-deep-primary">
						FAQs
					</h1>
					<div></div>
				</div>
				{/* Ensure scrolling on smaller screens */}
				<div className="w-full bg-white font-cinzel-decorative max-w-6xl mb-8 lg:overflow-y-hidden overflow-y-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
					{faqs.map((faq, index) => (
						<div
							key={index}
							className="bg-white border border-b-primary shadow rounded-tl-3xl p-6 transform transition duration-500 hover:-translate-y-2 hover:shadow-2xl"
						>
							<h2 className="text-lg font-semibold text-deep-primary">
								{faq.question}
							</h2>
							<p className="text-gray-700 mt-4 text-sm">{faq.answer}</p>
						</div>
					))}
				</div>
				<div>
					<button
						className="font-cinzel-decorative border border-primary bg-tertiary px-5 py-1 rounded-full"
						onClick={() => navigate(PATHS.BEGIN)}
					>
						Begin
					</button>
				</div>
			</div>
		</Layout>
	);
};

export default FAQ;

const faqs = [
	{
		question: "What is Love on Trial?",
		answer:
			"It’s an unconventional matchmaking show where seekers explore potential romantic connections through candid conversations, a spin on dates, and a no-camera trial period to decide if they’ve found “the one.”",
	},
	{
		question: "How does the matching process work?",
		answer:
			'Seekers complete two steps: the first step, an onboarding questionnaire is where we determine seekers fit for the show. The second step, the matching stage to identify who seekers think their "dream" match, “could-never-work” match, and “maybe-we-can-work” match are.',
	},
	{
		question: "Do I have to pay to participate?",
		answer: "No, participating in the show is free.",
	},
	{
		question: "Will my onboarding stage form responses be shared with others?",
		answer:
			"No, all responses are confidential and used solely for matching purposes.",
	},
	{
		question: "Where will the show air?",
		answer:
			"Love on Trial will premiere on YouTube, with additional snippets on platforms like Snapchat, TikTok and Instagram.",
	},
	{
		question: "Is there anything else I should be aware of before I apply?",
		answer:
			'The production team selects the “maybe-we-can-work” match based off your responses, and other factors. You would only know which seeker on the show was a "Dream", "Never" or "Maybe" after the show',
	},
];