import React, { useState, useEffect } from "react";
import "./TrailCursor.css";

interface Trail {
	x: number;
	y: number;
	id: number;
}

const TrailCursor: React.FC = () => {
	const [trail, setTrail] = useState<Trail[]>([]);

	useEffect(() => {
		const addTrail = (e: MouseEvent): void => {
			setTrail((current) => [
				...current,
				{ x: e.clientX, y: e.clientY, id: Date.now() },
			]);
		};

		const removeOldTrail = (): void => {
			setTrail((current) => current.slice(1));
		};

		window.addEventListener("mousemove", addTrail);
		const interval = setInterval(removeOldTrail, 100);

		return () => {
			window.removeEventListener("mousemove", addTrail);
			clearInterval(interval);
		};
	}, []);

	return (
		<>
			{trail.map((t) => (
				<div
					key={t.id}
					className="trail"
					style={{
						top: `${t.y}px`,
						left: `${t.x}px`,
					}}
				/>
			))}
		</>
	);
};

export default TrailCursor;
