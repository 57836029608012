import React from "react";
import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../routes/routes.path";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Helmet } from "react-helmet-async";

const About = () => {
	const navigate = useNavigate();
	return (
		<Layout>
			<Helmet>
				<title>Love on Trial | About</title>
			</Helmet>
			<div className="lg:flex lg:flex-col lg:justify-center items-center h-screen lg:m-auto lg:h-dvh overflow-y-auto md:overflow-y-scroll py-16 text-center ">
				<div className="flex justify-between items-center lg:px-0 px-5 lg:w-[90%] w-[95%] mb-6">
					<IoIosArrowRoundBack
						className="text-4xl text-deep-primary cursor-pointer"
						onClick={() => navigate("/")}
					/>
					<h1 className="text-4xl font-bold font-cinzel text-deep-primary">
						About
					</h1>
					<div></div>
				</div>
				<div className="space-y-4 font-cinzel-decorative lg:px-44  px-4">
					<p>
						At Love on Trial, we believe in real connections. Our goal is to
						bring together men and women who are ready to explore what love
						means in a fresh and exciting way. We’ve created a space where
						people can be their true selves, meet new connections, and learn
						about their own desires and expectations along the way. We've had
						this going privately with unique connections formed at every turn
						and now we want to make it official with the public.
					</p>
					<p>~</p>
					<p>
						The show is about more than just finding a match—it’s about
						understanding what works and what doesn’t in love. Through fun,
						honest interviews, surprising twists, and real conversations, we let
						our seekers decide what they want in a partner and give them the
						opportunity to see if there’s a genuine connection. It’s about
						chemistry, but also about knowing what feels right for you.
					</p>
					<p>~</p>
					<p>
						Our vision is simple—create a space where people can be themselves,
						meet new connections, and learn about their own selves along the
						way. Do you think you know your match? Or are you one to take up a
						challenge? Then take the first step by clicking the button below to
						put it to the test.
					</p>
					<p>~</p>
					<p>
						Love on Trial is here to give you a front-row seat to the highs and
						lows of personal expectations and love. #LoveOnTrial 💞
					</p>
				</div>
				<button
					className="font-cinzel-decorative mt-12 border border-primary bg-tertiary px-5 py-1 rounded-2xl lg:mb-0 mb-10"
					onClick={() => navigate(PATHS.BEGIN)}
				>
					Begin
				</button>
			</div>
		</Layout>
	);
};

export default About;
