import React from 'react'

const Loader = () => {
  return (
		<div className="flex items-center justify-center h-screen">
			<img
				src="/assets/love-on-trial-logo.png"
				alt="Loading"
				className="w-12 animate-pulse"
			/>
		</div>
	);
}

export default Loader